// JavaScript Document


jQuery( document ).ready(function($) {
    
	$(document).foundation();
	
	$('.hamburger').on('click', function(){
		
		$('.hamburger').toggleClass('is-active');
		$('.overlay-menu').toggleClass('is-active'); 
		$('html').toggleClass('locked');

	});
		$(window).scroll(function() {
		 if ($(this).scrollTop() > 250)
		 {
		  $('.sticky-header').addClass("active");
		 }
		 else
		 {
		  $('.sticky-header').removeClass("active");
		 }
		});
	//ACCORDION MOBILE MENU
	$('.is-accordion-submenu-parent a').click(function(){
        $(this).unbind('click');
	});
	
	// Customizing a reveal set
	window.sr = ScrollReveal();
	
	sr.reveal('.rvl', {
		duration: 500,
		distance:'0px',
		scale:1,
		delay:100
		});
	
	sr.reveal('.str', { 
		duration: 500,
		origin: 'bottom',
		scale: 1
	}, 70);
	
$('#newsletter_form input[name=email]').focus( function(){
		$('#newsletter_form input[name=cpt]').val('425');
	});

	//CONTACT FORM
	$('#newsletter_form')
	// to prevent form from submitting upon successful validation
	  .on("submit", function(ev) {
		ev.preventDefault();
		console.log("Submit for form id "+ev.target.id+" intercepted");
	 })	
	.on("forminvalid.zf.abide", function(ev,frm) {
		 console.log("Form id "+ev.target.id+" is invalid");
	  })
	.on("formvalid.zf.abide", function(ev,frm) {

			console.log("form is valid");
			$('#newsletter_form .loading').css('display','inline-block');
			var that = $('#newsletter_form'),
			url = that.attr("action"),
			method = that.attr("method"),
			data = {};
			that.find("[name]").each( function() {
				var that = $(this),
					name = that.attr("name"),
					value = that.val();
					data[name] = value;
			});
			
			if($('#newsletter_form input[name=cpt]').val() == '425') {
				$.ajax({
					url: url,
					type: method,
					data: data,
					success: function(response) {
						console.log('success');
						$('#newsletter_form').hide(); 
						$('.newsletter .successMessage').show(); 

					},
					error: function(response){
						$('#newsletter_form .loading').hide();
					}
				});
			} else {
				$('#newsletter_form .loading').hide();
			}
		});
	
	
});
